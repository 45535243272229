<template lang="pug">
v-card.textarea-style
    v-card-title.d-flex.justify-space-between
        h3 {{$t('ADDPROCESSDIALOG.EDIT')}}
        v-btn.ml-3.add-deal-btn(v-if='editStageDefData.length <= 10' @click='addStageInput' width='40' height='40' :ripple='false' color='success' depressed dark fab rounded)
            v-icon(size='16') icon-add
    v-card-text(style="max-height: 1000px;" ref="content")
        v-form(ref='form')
            v-row.align-center.mt-1.mb-4(no-gutters)
                v-col(cols='12')
                    v-text-field#options.v-text-field__slot(v-model='editStageTypeData.stage_type_name' label='商業流程名稱' :rules="formRules.nameRule" validate-on-blur required="required")
            draggable(:list='editStageDefData')
                v-card.process-setting-item.pa-4(flat v-for='(item, index) in editStageDefData' :key='index')
                    v-row.align-center.justify-space-between.my-0(no-gutters)
                        v-col(cols='auto').d-flex.align-center.justify-start
                             h6.ma-0(v-if='$i18n.locale == "zh_TW"') 第 {{ index + 1 }} 個階段 
                             h6.ma-0(v-if='$i18n.locale == "zh_TW"') 第 {{ index + 1 }} 个阶段 
                             h6.ma-0(v-else) Step {{ index + 1 }} 
                        v-col(cols='1').d-flex.align-center.justify-center
                            v-btn( :disabled='editStageDefData.length == 1' rounded icon @click='delStep(item, index)')
                                v-icon.v-btn__content.delBtn.icon(size='20' color='gray') icon-remove
                    v-row.align-center.my-0.mt-4(no-gutters)
                        v-col(cols='12')
                            v-text-field#options.v-text-field__slot.height-48(v-model='editStageDefData[index].stage_name' :label=`$t('ADDPROCESSDIALOG.STAGE_NAME')`, :rules="formRules.stepRule" validate-on-blur required="required")
                        v-col(cols='12')
                            v-select.edit-and-add-Progress.v-text-field__slot.height-48(v-model='editStageDefData[index].stage_color' :items='stageDefColorList' item-text='name' item-value='hexCode' solo dense :label=`$t('ADDPROCESSDIALOG.COLOR')`,)
                    v-row.align-center.my-0( v-if='editStageDefData[index].isMore' no-gutters)
                        v-col.pr-2(cols='6')
                            v-text-field#options.v-text-field__slot.height-48(v-model='editStageDefData[index].stage_probability' :label=`$t('ADDPROCESSDIALOG.PROBABILITY')`, :rules="formRules.probabilityRule" validate-on-blur required="required" suffix="%" v-if="stageType.type!='CASE'")
                        v-col.pl-2(cols='6')
                            v-text-field#options.v-text-field__slot.height-48(v-model='editStageDefData[index].zombie_day' :label=`$t('ADDPROCESSDIALOG.FINISH_DAY')`, :rules="formRules.zombieRule" validate-on-blur required="required" :suffix=`$t('ADDPROCESSDIALOG.DAY')` v-if="stageType.type!='CASE'")
                        v-col.pl-2(cols='12')
                            v-text-field#options.v-text-field__slot.height-48(v-model='editStageDefData[index].zombie_day' :label=`$t('ADDPROCESSDIALOG.FINISH_DAY')`, :rules="formRules.zombieRule" validate-on-blur required="required" :suffix=`$t('ADDPROCESSDIALOG.DAY')` v-if="stageType.type=='CASE'")
                        v-col(cols='12')
                            v-textarea.v-text-field__slot(v-model='editStageDefData[index].guideline' :label=`$t('ADDPROCESSDIALOG.GUIDE')`,)   
                    v-row.align-center.my-0(no-gutters)
                        v-col(cols='12').d-flex.align-center.justify-end
                            v-btn.ml-0.pa-0(v-if='editStageDefData[index].isMore' color='primary' text @click='less(index)') LESS
                            v-btn.ml-0.pa-0(v-else color='primary' text @click='more(index)') MORE                         
            v-row.align-center.mt-1.mb-4(no-gutters v-if='editStageDefData.length == 0')
                v-col(cols='12')
                    h6.t-pink  {{$t('ADDPROCESSDIALOG.ERROR')}}
    v-card-actions(v-if='!isExceed')
        v-row(no-gutters)
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
    v-card-actions(v-else)
        v-row(no-gutters)
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import draggable from 'vuedraggable';
    import stageDefColorList from "@/array/the_stageDefColorList";
    import StageDataService from '@/services/StageDataService';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {
            stageType: {
                type: Object,
                required: true,
            }, 
            stageDef: {
                type: Array,
                required: true,
            }
        },
        components: {
            draggable,
            messageDialog,
            successDialog,
            errorDialog,
        },
        data() {
            return {
                btnWord:"MORE",
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                stageDefColorList: stageDefColorList,
                colorCode: '',
                messageContent: '',
                selectedId: '',
                selectedIndex: '',
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_PROCESS'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                        ],
                    stepRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_STAGE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    probabilityRule: [(v) => /^([1-9][0-9]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
                    zombieRule: [(v) => /^([1-9][0-9]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
                },
                editStageTypeData: this.stageType,
                editStageDefData: this.stageDef,
                isExceed: true,
                deleteStepList:[],
            }
        },
        created() {
            for(let i = 0; i < this.editStageDefData.length; i++) {
                Vue.set(this.editStageDefData[i], 'isMore', true);
            }
            if (this.editStageDefData.length == 1) {
                this.isExceed = false;
            }
        },
        updated() {
            let h = this.$refs.content.clientHeight;
            if (h > 850) {
                this.isExceed = true;
            } else {
                this.isExceed = false;
            }
        },
        methods: {
            validate() {
                this.$refs.form.validate();
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    if (this.messageContent == 'DELETE') {
                        if(this.selectedId == undefined){
                            this.editStageDefData.splice(this.selectedIndex, 1);
                        }
                        else if(typeof(this.selectedId) == 'number'){
                            StageDataService.deleteStep(this.selectedId)
                            .then(response => {
                                if (response.data == "Have Opportunity") {
                                    this.showErrorDialog(i18n.t('ADDPROCESSDIALOG.ERROR_2'));
                                } else {
                                    this.showSuccessDialog();
                                    this.editStageDefData.splice(this.selectedIndex, 1);
                                    this.deleteStepList.push(this.selectedId)
                                }
                            });
                        }
                    } 
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            async addStageInput() {
                let amount = this.editStageDefData.length;
                if (amount < 11) {
                    this.editStageDefData.push({
                        sequence_number: '',
                        stage_name: '',
                        stage_probability: '',
                        zombie_day: '',
                        guideline: '',
                        stage_color: '',
                        isMore: false,
                    });
                }
                let r = amount % 6
                switch (r) {
                    case 0:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[0].hexCode;
                        break;
                    case 1:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[1].hexCode;
                        break;
                    case 2:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[2].hexCode;
                        break;
                    case 3:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[3].hexCode;
                        break;
                    case 4:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[4].hexCode;
                        break;
                    case 5:
                        this.editStageDefData[amount].stage_color = this.stageDefColorList[5].hexCode;
                        break;
                    default:
                        break;
                }
            },
            onConfirm() {
                if(this.validateForm()){
                    let i = 1;
                    this.editStageDefData.forEach(step => {
                        if (step.isMore == false) {
                            step.stage_probability = null;
                            step.zombie_day = null;
                            step.guideline = null;
                        } else {
                            if (step.stage_probability == "") {
                                step.stage_probability = null;
                            }
                            if (step.zombie_day == "") {
                                step.zombie_day = null;
                            }
                        }
                        step.sequence_number = i;
                        i++;
                    });

                    let processData = {
                        id: this.editStageTypeData.id,
                        stage_type_name: this.editStageTypeData.stage_type_name,
                        stagedef_set: this.editStageDefData
                    }
                    
                    StageDataService.update(processData)
                    .then(response => {
                        this.$emit('emitEditProcessDialog', true);
                        this.$emit('emitEditProcessData', response.data);
                    });
                }
            },
            onCancel() {
                this.$emit('emitEditProcessDialog', false,this.deleteStepList);
            },
            more(index) {
                this.bool = true;
                this.editStageDefData[index].isMore = true;
            },
            less(index) {
                this.bool = false;
                this.editStageDefData[index].isMore = false;
            },
            delStep(item, index) {
                this.selectedId = item.id;
                this.selectedIndex = index;
                this.messageContent = 'DELETE';
                this.showMessageDialog(i18n.t('ADDPROCESSDIALOG.MESSAGE'));
            },
        }
    });
</script>

<style lang="scss">
.v-btn__content.delBtn
{
    bottom: 10px;
    left:5px 
    
}
.v-btn__content.delBtn.icon{
    top:1px;
    left:0px 
}
</style>